// src/app/page.tsx
import React from 'react';
import ImageCustom from '@/components/elements/ImageCustom';
import RowNoData from '@/components/elements/RowNoData';
import { useTranslation } from 'next-i18next';
import { HeadObj } from '@/objects/Page';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const NotFoundPage = () => {

  const { t } = useTranslation("common");

  React.useEffect(() => {
    const footer = document.getElementById("footer_page");
    if (footer) {
      footer.remove();
    }

    // Quita el scroll
    document.body.style.overflow = 'hidden';

    // Asegúrate de restablecer el overflow cuando el componente se desmonte
    return () => {
      document.body.style.overflow = 'auto';
    };

  }, []);

  return (
    <div id="blur_background" style={{ marginTop: "-100px", textAlign: "center", height: "110vh", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <div style={{ marginTop: "20px", marginBottom: "-40px" }}>
        <RowNoData texto={t("common:encontrado.titulo")} />
      </div>
      <div style={{ marginRight: "20px" }}>
        <ImageCustom src="/images/404_image.png" title="404 QuieroLibros" alt="404" width={500} height={500} />
      </div>
    </div>
  );
};

export default NotFoundPage;


export async function getStaticProps(context: any) {
  //Traducciones
  let { locale } = context;

  if (!locale) {
    locale = 'es'; // Reemplaza 'es' con tu localización predeterminada
  }

  const pathActual = "/404"

  //console.log('Idioma Cookie:' + getCookiePais(context));
  const headerObj: HeadObj = {
    title: "404 Not Found - QuieroLibros",
    descripcionKey: "404 Not Found - QuieroLibros",
    indexKeywords: "404 Not Found - QuieroLibros",
    linkIdioma: locale
  };

  //console.log("ServerSideTranslation " + JSON.stringify(await serverSideTranslations(locale, ['common', "contenido"])));
  return {
    props: {
      headerObj: headerObj, // Pasar el objeto de encabezado
      pathActual: pathActual,
      ...(await serverSideTranslations(locale, ['common', "contenido", "header"])),
    },
    //revalidate: 60, // Regenera la página cada 60 segundos
  };
}