import { Card } from '@mui/material';
import Texto from './Texto';


//Definimos los tipos de datos que va a recibir el componente en forma de interfaz
function RowNoData({ texto }: { titulo?: string, texto: string }) {

    const styleCard = {
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        textAlign: 'center',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: 'white',
        borderRadius: '4px',
        margin: '0.5rem'
    };

    const listStyle = {
        textAlign: 'center',
        height: '95%'
    };

    const noDataStyle = {
        width: '100%',
        maxWidth: 500,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Card sx={{ ...styleCard, ...listStyle, ...noDataStyle }}>
                    <div style={{ padding: '10px', paddingBottom: '10px', paddingLeft: '15px', paddingRight: '15px' }}>
                        <Texto > {texto} </Texto>
                    </div>
                </Card>
            </div>
        </div>
    );

}

export default RowNoData;
